import { Control, useController, useWatch } from 'react-hook-form';
import { Schema_LoanSelection } from './LoanSelection';
import { useEffect } from 'react';
import { formatLocaleNumber } from '../consors/utils/number.utils';
import { EFFECTIVE_RATE } from '../utils/constants';

type Props = {
    control: Control<Schema_LoanSelection>;
};

export function LoanSelectionMonthlyRate({ control }: Props) {
    const creditsum = useWatch<Schema_LoanSelection, 'creditsum'>({ control, name: 'creditsum' });
    const runtime = useWatch<Schema_LoanSelection, 'runtime'>({ control, name: 'runtime' });
    const { field } = useController<Schema_LoanSelection, 'monthlyRate'>({ control, name: 'monthlyRate' });
    const range = useWatch({ control, name: 'range' });

    useEffect(() => {
        const monthlyRate = (creditsum * (EFFECTIVE_RATE / 1200)) / (1 - Math.pow(1 / (1 + EFFECTIVE_RATE / 1200), runtime));
        field.onChange(Number(monthlyRate.toFixed(2)));
    }, [creditsum, runtime]);

    const formattedValue =
        field.value != null && !Number.isNaN(field.value)
            ? formatLocaleNumber(field.value, navigator.language, { minimumFractionDigits: 2 })
            : '';
    return range != null ? null : (
        <>
            <div className="tooltip">
                <p className="tooltip-text">
                    mögliche Monatsrate{' '}
                    <i className="tooltip-icon" id="interaction-pk-monthlyRateMoreInfo">
                        ?
                    </i>
                </p>
                <div className="tooltip-content">
                    <div className="close">
                        <span />
                        <span />
                    </div>
                    <p>
                        <b>Berechnungsgrundlage für die angegebene Rate (Bonität vorausgesetzt):</b>
                    </p>
                    <p>
                        Nettobetrag 10.000, 5,20% geb. Sollzins, 5,32% eff. Jahreszins, 84 Monate Laufzeit, Gesamtbetrag 12027,11€, mtl.
                        Rate 143,19€, DSL Bank. Diese Musterfinanzierung entspricht dem repräsentativen Beispiel nach § 17 PAngV.
                    </p>
                </div>
            </div>

            <p className="big">
                <span id="mtl_rate">{formattedValue}</span>&nbsp;€
            </p>
        </>
    );
}
