import * as Sentry from '@sentry/react';
import { MIN_AGE } from './constants';

export function objectKeys<Obj extends object>(obj: Obj): Array<keyof Obj> {
    return Object.keys(obj) as Array<keyof Obj>;
}

export function getISO8601(date: Date): string {
    return date.toISOString().slice(0, 10);
}

export function isValidDate(str?: string) {
    try {
        if (str == null) return false;
        const expl = str.split('-');
        if (expl.length !== 3) return false;
        // TODO more ...
        const date = new Date(str);
        const isoStr = getISO8601(date);
        return isoStr === str;
    } catch (e: unknown) {
        return false;
    }
}

export const GENERATE_YEARS = (length: number, from = new Date().getFullYear()): Array<string> =>
    Array.from({ length }, (_, i) => (from - i).toString());

export const regex_currency = /^\d+(\.\d+)?$/;
export const regex_decimal = /^\d+(,\d+)?$/;
export const regex_integer = /^\d+$/;
export const regex_textonly = /^[a-zA-Z-ÄÜÖäüöß. ]*$/;
// eslint-disable-next-line no-useless-escape
export const regex_phone = /(\(?([\d \-\)\–\+\/\(]+){6,}\)?([ .\-–\/]?)([\d]+))/;
export const regex_phone_mobile = /0[0-9/() -]*/;
export const regex_iban =
    /^(?:AD)\d{10}[A-Z0-9]{12}|(?:AE|IL|TL)\d{21}|(?:AL|CY)\d{10}[A-Z0-9]{16}|(?:AT|BA|EE|LT|XK)\d{18}|(?:AZ)\d{2}[A-Z]{4}[A-Z0-9]{20}|(?:BE)\d{14}|(?:BG)\d{2}[A-Z]{4}\d{6}[A-Z0-9]{8}|(?:BH)\d{2}[A-Z]{4}[A-Z0-9]{14}|(?:BI|MR)\d{25}|(?:BR)\d{25}[A-Z]{1}[A-Z0-9]{1}|(?:BY)\d{2}[A-Z0-9]{4}\d{4}[A-Z0-9]{16}|(?:CH|LI)\d{7}[A-Z0-9]{12}|(?:CR|DE|ME|RS|VA)\d{20}|(?:CZ|ES|SE|SK|TN)\d{22}|(?:DK|FI|FO|GL|SD)\d{16}|(?:DO)\d{2}[A-Z0-9]{4}\d{20}|(?:EG)\d{27}|(?:FR|MC)\d{12}[A-Z0-9]{11}\d{2}|(?:GB|IE)\d{2}[A-Z]{4}\d{14}|(?:GE)\d{2}[A-Z]{2}\d{16}|(?:GI)\d{2}[A-Z]{4}[A-Z0-9]{15}|(?:GR)\d{9}[A-Z0-9]{16}|(?:GT)\d{2}[A-Z0-9]{24}|(?:HR)\d{19}|(?:HU|PL)\d{26}|(?:IQ)\d{2}[A-Z]{4}\d{15}|(?:IS)\d{24}|(?:IT|SM)\d{2}[A-Z]{1}\d{10}[A-Z0-9]{12}|(?:JO)\d{2}[A-Z]{4}\d{4}[A-Z0-9]{18}|(?:KW)\d{2}[A-Z]{4}[A-Z0-9]{22}|(?:KZ|LU)\d{5}[A-Z0-9]{13}|(?:LB)\d{6}[A-Z0-9]{20}|(?:LC)\d{2}[A-Z]{4}[A-Z0-9]{24}|(?:LV)\d{2}[A-Z]{4}[A-Z0-9]{13}|(?:LY|PT|ST)\d{23}|(?:MD)\d{2}[A-Z0-9]{20}|(?:MK)\d{5}[A-Z0-9]{10}\d{2}|(?:MT)\d{2}[A-Z]{4}\d{5}[A-Z0-9]{18}|(?:MU)\d{2}[A-Z]{4}\d{19}[A-Z]{3}|(?:NL)\d{2}[A-Z]{4}\d{10}|(?:NO)\d{13}|(?:PK|RO)\d{2}[A-Z]{4}[A-Z0-9]{16}|(?:PS|QA)\d{2}[A-Z]{4}[A-Z0-9]{21}|(?:SA)\d{4}[A-Z0-9]{18}|(?:SC)\d{2}[A-Z]{4}\d{20}[A-Z]{3}|(?:SI)\d{17}|(?:SV)\d{2}[A-Z]{4}\d{20}|(?:TR)\d{8}[A-Z0-9]{16}|(?:UA)\d{8}[A-Z0-9]{19}|(?:VG)\d{2}[A-Z]{4}\d{16}$/;
export const regex_alphanumeric = /^[\w]+$/;

export function getDateMinus18(now = new Date()) {
    const minus = new Date(now);
    minus.setFullYear(minus.getFullYear() - MIN_AGE);
    return minus;
}

export function makeISODate({ day, month, year }: { day: string | undefined; month: string | undefined; year: string | undefined }) {
    return `${year}-${month?.padStart(2, '0')}-${day?.padStart(2, '0')}`;
}

export function isValidIBAN(value: string) {
    const iban = String(value)
        .toUpperCase()
        .replace(/[^A-Z0-9]/g, '');
    return (
        /^(?:AD)\d{10}[A-Z0-9]{12}|(?:AE|IL|TL)\d{21}|(?:AL|CY)\d{10}[A-Z0-9]{16}|(?:AT|BA|EE|LT|XK)\d{18}|(?:AZ)\d{2}[A-Z]{4}[A-Z0-9]{20}|(?:BE)\d{14}|(?:BG)\d{2}[A-Z]{4}\d{6}[A-Z0-9]{8}|(?:BH)\d{2}[A-Z]{4}[A-Z0-9]{14}|(?:BI|MR)\d{25}|(?:BR)\d{25}[A-Z]{1}[A-Z0-9]{1}|(?:BY)\d{2}[A-Z0-9]{4}\d{4}[A-Z0-9]{16}|(?:CH|LI)\d{7}[A-Z0-9]{12}|(?:CR|DE|ME|RS|VA)\d{20}|(?:CZ|ES|SE|SK|TN)\d{22}|(?:DK|FI|FO|GL|SD)\d{16}|(?:DO)\d{2}[A-Z0-9]{4}\d{20}|(?:EG)\d{27}|(?:FR|MC)\d{12}[A-Z0-9]{11}\d{2}|(?:GB|IE)\d{2}[A-Z]{4}\d{14}|(?:GE)\d{2}[A-Z]{2}\d{16}|(?:GI)\d{2}[A-Z]{4}[A-Z0-9]{15}|(?:GR)\d{9}[A-Z0-9]{16}|(?:GT)\d{2}[A-Z0-9]{24}|(?:HR)\d{19}|(?:HU|PL)\d{26}|(?:IQ)\d{2}[A-Z]{4}\d{15}|(?:IS)\d{24}|(?:IT|SM)\d{2}[A-Z]{1}\d{10}[A-Z0-9]{12}|(?:JO)\d{2}[A-Z]{4}\d{4}[A-Z0-9]{18}|(?:KW)\d{2}[A-Z]{4}[A-Z0-9]{22}|(?:KZ|LU)\d{5}[A-Z0-9]{13}|(?:LB)\d{6}[A-Z0-9]{20}|(?:LC)\d{2}[A-Z]{4}[A-Z0-9]{24}|(?:LV)\d{2}[A-Z]{4}[A-Z0-9]{13}|(?:LY|PT|ST)\d{23}|(?:MD)\d{2}[A-Z0-9]{20}|(?:MK)\d{5}[A-Z0-9]{10}\d{2}|(?:MT)\d{2}[A-Z]{4}\d{5}[A-Z0-9]{18}|(?:MU)\d{2}[A-Z]{4}\d{19}[A-Z]{3}|(?:NL)\d{2}[A-Z]{4}\d{10}|(?:NO)\d{13}|(?:PK|RO)\d{2}[A-Z]{4}[A-Z0-9]{16}|(?:PS|QA)\d{2}[A-Z]{4}[A-Z0-9]{21}|(?:SA)\d{4}[A-Z0-9]{18}|(?:SC)\d{2}[A-Z]{4}\d{20}[A-Z]{3}|(?:SI)\d{17}|(?:SV)\d{2}[A-Z]{4}\d{20}|(?:TR)\d{8}[A-Z0-9]{16}|(?:UA)\d{8}[A-Z0-9]{19}|(?:VG)\d{2}[A-Z]{4}\d{16}$/.test(
            iban
        ) && isValidIBANChecksum(iban)
    );
}

function isValidIBANChecksum(iban: string) {
    if (!window.BigInt) {
        return true;
    }
    const providedChecksum = parseInt(iban.slice(2, 4), 10);
    const temp = iban.slice(3) + iban.slice(0, 2) + '00';
    let numberString = '';
    for (let n = 1; n < temp.length; n++) {
        const c = temp.charCodeAt(n);
        if (c >= 65) {
            numberString += (c - 55).toString();
        } else {
            numberString += temp[n];
        }
    }
    while (numberString.length > 2) {
        const part = numberString.slice(0, 6);
        numberString = (parseInt(part, 10) % 97).toString() + numberString.slice(part.length);
    }
    const rest = BigInt.asIntN(64, BigInt(numberString) % BigInt(97));
    return 98 - Number(rest) === providedChecksum;
}

export const AUTO_COMPLETE = {
    'given-name': 'given-name',
    'family-name': 'family-name',
    email: 'email',
    'postal-code': 'postal-code',
    'cc-number': 'cc-number',
    'bday-day': 'bday-day',
    'bday-month': 'bday-month',
    'bday-year': 'bday-year',
    'tel': 'tel'
} as const;

export const getPromiseState = (promise: Promise<any>) => {
    const t = {};
    return Promise.race([promise, t])
      .then(v => (v === t)? "pending" : "fulfilled", () => "rejected");
}