import * as Sentry from '@sentry/react';
import { createRoot } from 'react-dom/client';
import { LoanSelection } from './loanSelection/LoanSelection';

Sentry.init({
    dsn: 'https://f5487a057f5b447cbb2e87e8c947c680@et.kredit.de/13',
    tracesSampleRate: 0.1,
    environment: STAGE,
    release: SENTRY_RELEASE,
    enabled: STAGE !== 'local',
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,

    integrations: [
        new Sentry.BrowserTracing(),
        new Sentry.Replay({
            maskAllInputs: true,
            blockAllMedia: true,
            maskAllText: true,
        }),
    ],

    beforeSend(event, hint) {
        let gtm = false;
        try {
            gtm = hint.originalException.stack.indexOf('tsolution') > -1;
            if(gtm) return null; // this drops the event
        } catch (e) {
            console.error(e);
        }
        event.tags = event.tags || {};
        event.extra = event.extra || {};
        if (gtm) {
            const dataLayer = window.dataLayer || [];
            event.tags.logger = 'gtm';
            event.extra.dataLayer = dataLayer.map(
              function(i) {
                try {
                  return JSON.stringify(i, null, ' ');
                } catch(error) {
                  return '[cannot be serialized]: ' + `${error?.message}`;
                }
              }
            );
          }
        event.tags.logger = event.tags.logger || '_default_';
        return event;
    },
});

const rootElement = document.getElementById('rootLoanSelection');
if (rootElement) {
    const root = createRoot(rootElement);
    root.render(<LoanSelection />);
}
