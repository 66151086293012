import { Control, UseFormSetValue, useController } from 'react-hook-form';
import { Schema_LoanSelection } from './LoanSelection';
import { objectKeys } from '../consors/utils/utils';

type Props = {
    control: Control<Schema_LoanSelection>;
    defaultPurpose: string;
    setValue: UseFormSetValue<Schema_LoanSelection>;
};

export const ENUM_MONTHLY_RATE_RANGE = {
    "50": "50",
    "100": "100",
    "200": "200",
    "350": "350",
    "600": "600",
    "700": "700",
    reschedule: 'reschedule',
    notSpecified: 'notSpecified',
} as const;

export const ENUM_ALTERNATIVE_RUNTIME = {
    "NOT_SPECIFIED": "NOT_SPECIFIED",
    "RESCHEDULE": "RESCHEDULE",
} as const;
export const VALUES_ALTERNATIVE_RUNTIME: Record<keyof typeof ENUM_ALTERNATIVE_RUNTIME, string> = {
    RESCHEDULE: "Ich möchte umschulden",
    NOT_SPECIFIED: "Keine Angabe",
}

export const VALUES_MONTHLY_RATE_RANGE: Record<keyof typeof ENUM_MONTHLY_RATE_RANGE, string> = {
    50: 'Unter € 50',
    100: '€ 50–100',
    200: '€ 100–200',
    350: '€ 200–350',
    600: '€ 350–600',
    700: 'Über € 600',
    reschedule: 'Ich möchte umschulden',
    notSpecified: 'Keine Angabe',
} as const;

export function LoanSelectionMonthlyRateRange({ control, defaultPurpose, setValue }: Props) {
    const { field } = useController({ control, name: 'range', shouldUnregister: false });
    const onClick = (key: keyof typeof ENUM_MONTHLY_RATE_RANGE) => {
        // field.onChange(key);
        setValue("range", key);
        if(key === "reschedule") {
            setValue("usage", "Umschuldung");
        } else {
            setValue("usage", defaultPurpose);
        }
    };

    return (
        <div className="item-60">
            <label htmlFor="year">Wie viel möchten Sie monatlich ca. zurückzahlen (monatliche Rate):</label>
            <div id="pk_monthRate" className="duration durationMonthlyRate">
                {objectKeys(VALUES_MONTHLY_RATE_RANGE).map((key) => (
                    <div key={key} id={`form-pk-start-chooseMonthlyRate-${key}`} className={field.value === key ? "active" : ""} onClick={() => onClick(key)}>
                        {VALUES_MONTHLY_RATE_RANGE[key]}
                    </div>
                ))}
            </div>
        </div>
    );
}
