import * as Sentry from '@sentry/react';

export function getGrowthbookFeature<TDefaultValue = any>(featureKey: string, defaultValue: TDefaultValue): TDefaultValue {
    try {
		const el = (document.getElementById('header_gb') as HTMLInputElement)?.getAttribute("data-features");
		const features = typeof el === "string" ? JSON.parse(el) : {};
		return features[featureKey] ?? defaultValue;
    } catch (e: unknown) {
        Sentry.captureException(e, {
            extra: {
                type: 'getGrowthbookFeature',
                featureKey,
            },
        });
        return defaultValue;
    }
}
