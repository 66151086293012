import { Control, useController } from 'react-hook-form';
import { Schema_LoanSelection } from './LoanSelection';

type Props = {
    control: Control<Schema_LoanSelection>;
};

const RUNTIMES = [12, 24, 36, 48, 60, 72, 84, 96, 108, 120] as const;

export function LoanSelectionRuntime({ control }: Props) {
    const hasAdditionalRuntime = window.location.pathname.includes('pk-antrag');
    const { field } = useController({ control, name: 'runtime' });

    const onClick = (runtime: number) => {
        field.onChange(runtime);
    };

    const className = 'duration durationMonthlyRate';
    return (
        <div className="item-60">
            <label htmlFor="year">Wählen Sie Ihre gewünschte Laufzeit in Monaten:</label>
            <div id="pk_duration" className={className}>
                {RUNTIMES.map((runtime) => (
                    <div
                        key={runtime}
                        className={runtime === field.value ? 'active' : ''}
                        id={`form-pk-start-chooseDuration-${runtime}`}
                        onClick={() => onClick(runtime)}
                    >
                        {runtime}
                    </div>
                ))}
                {hasAdditionalRuntime && (
                    <div
                        key={'240'}
                        className={240 === field.value ? 'active' : ''}
                        id={`form-pk-start-chooseDuration-240`}
                        onClick={() => onClick(240)}
                    >
                        240
                    </div>
                )}
            </div>
        </div>
    );
}
