import { objectKeys } from "./utils";

export const BOOL = {
    TRUE: "TRUE",
    FALSE: "FALSE",
} as const;

export const CC_CONSORS_FORM_STEPS = {
    'personal': 'personal',
    'other': 'other',
    'submitCreditCardRequest': 'submitCreditCardRequest',
    'decision': 'decision',
} as const;
export const STEPS = objectKeys(CC_CONSORS_FORM_STEPS);

export type ACTIVE_STEPS = typeof STEPS[number];

export const MIN_AGE = 18;
export const RESIDENT_SINCE_MONTH = 25;

export const EnumLikeGender = { male: 'male', female: 'female', diverse: 'diverse' } as const;
export const GENDERS: Record<keyof typeof EnumLikeGender, string> = {
    male: 'Herr',
    female: 'Frau',
    diverse: 'Divers',
} as const;

export const EnumLikePersonalSituation = {
    single: 'single',
    married: 'married',
    cohabitation: 'cohabitation',
    separated: 'separated',
    divorced: 'divorced',
    widowed: 'widowed',
    other: 'other',
} as const;
export const PERSONAL_SITUATION: Record<keyof typeof EnumLikePersonalSituation, string> = {
    single: 'ledig',
    married: 'verheiratet',
    cohabitation: 'eheähnliche',
    separated: 'getrennt',
    divorced: 'geschieden',
    widowed: 'verwitwet',
    other: 'andere Beziehung',
} as const;

export const EnumLikeDays = {
    '1': '1',
    '2': '2',
    '3': '3',
    '4': '4',
    '5': '5',
    '6': '6',
    '7': '7',
    '8': '8',
    '9': '9',
    '10': '10',
    '11': '11',
    '12': '12',
    '13': '13',
    '14': '14',
    '15': '15',
    '16': '16',
    '17': '17',
    '18': '18',
    '19': '19',
    '20': '20',
    '21': '21',
    '22': '22',
    '23': '23',
    '24': '24',
    '25': '25',
    '26': '26',
    '27': '27',
    '28': '28',
    '29': '29',
    '30': '30',
    '31': '31',
} as const;
export const DAYS: Record<keyof typeof EnumLikeDays, string> = {
    '1': '01',
    '2': '02',
    '3': '03',
    '4': '04',
    '5': '05',
    '6': '06',
    '7': '07',
    '8': '08',
    '9': '09',
    '10': '10',
    '11': '11',
    '12': '12',
    '13': '13',
    '14': '14',
    '15': '15',
    '16': '16',
    '17': '17',
    '18': '18',
    '19': '19',
    '20': '20',
    '21': '21',
    '22': '22',
    '23': '23',
    '24': '24',
    '25': '25',
    '26': '26',
    '27': '27',
    '28': '28',
    '29': '29',
    '30': '30',
    '31': '31',
};

export const EnumLikeMonths = {
    '1': '1',
    '2': '2',
    '3': '3',
    '4': '4',
    '5': '5',
    '6': '6',
    '7': '7',
    '8': '8',
    '9': '9',
    '10': '10',
    '11': '11',
    '12': '12',
} as const;
export const MONTHS: Record<keyof typeof EnumLikeMonths, string> = {
    '1': 'Januar',
    '2': 'Februar',
    '3': 'März',
    '4': 'April',
    '5': 'Mai',
    '6': 'Juni',
    '7': 'Juli',
    '8': 'August',
    '9': 'September',
    '10': 'Oktober',
    '11': 'November',
    '12': 'Dezember',
} as const;

export const EnumLikeEmploymentType = {
    WORKER: 'WORKER',
    REGULAR_EMPLOYED: 'REGULAR_EMPLOYED',
    UNEMPLOYED: 'UNEMPLOYED',
    STUDENT: 'STUDENT',
    PUBLIC_SERVANT: 'PUBLIC_SERVANT',
    HOUSE_WIFE: 'HOUSE_WIFE',
    PENSIONER: 'PENSIONER',
    SELF_EMPLOYED: 'SELF_EMPLOYED',
    MANAGING_DIRECTOR: 'MANAGING_DIRECTOR',
} as const;

export const EMPLOYMENT_TYPE: Record<keyof typeof EnumLikeEmploymentType, string> = {
    REGULAR_EMPLOYED: 'Angestellter',
    WORKER: 'Arbeiter',
    UNEMPLOYED: 'Arbeitslos / Sonstige',
    STUDENT: 'Student',
    PUBLIC_SERVANT: 'Beamter',
    HOUSE_WIFE: 'Hausfrau- mann',
    PENSIONER: 'Rentner',
    SELF_EMPLOYED: 'Selbstständig',
    MANAGING_DIRECTOR: 'Vorstand',
} as const;

export const EnumLikeJobgroupSector = {
    CONSTRUCTION: 'CONSTRUCTION',
    SERVICES_INCLUDING_FREELANCE_PROFESSIONS: 'SERVICES_INCLUDING_FREELANCE_PROFESSIONS',
    MINING_QUARRYING_EARTH: 'MINING_QUARRYING_EARTH',
    TRADE_SERVICE_REPAIR_VEHICLES_CONSUMER_GOODS: 'TRADE_SERVICE_REPAIR_VEHICLES_CONSUMER_GOODS',
    FINANCIAL_INSTITUTIONS__INSURANCE_COMPANIES: 'FINANCIAL_INSTITUTIONS__INSURANCE_COMPANIES',
    AGRICULTURE_FORESTRY_FISHING_FISH_FARMING: 'AGRICULTURE_FORESTRY_FISHING_FISH_FARMING',
    MANUFACTURING: 'MANUFACTURING',
    TRAFFIC_MESSAGE_DELIVERY: 'TRAFFIC_MESSAGE_DELIVERY',
    OTHER: 'OTHER',
} as const;
export const JOBGROUP_SECTOR: Record<keyof typeof EnumLikeJobgroupSector, string> = {
    CONSTRUCTION: 'Baugewerbe',
    SERVICES_INCLUDING_FREELANCE_PROFESSIONS: 'Dienstleistungen einschließlich freier Berufe',
    MINING_QUARRYING_EARTH: 'Bergbau und Gewinnung von Steinen und Erden',
    TRADE_SERVICE_REPAIR_VEHICLES_CONSUMER_GOODS: 'Handel, Instandhalt. u. Reparatur KFZ u. Gebrauchsgütern',
    FINANCIAL_INSTITUTIONS__INSURANCE_COMPANIES: 'Finanzierungsinstitutionen u. Versicherungsunternehmen',
    AGRICULTURE_FORESTRY_FISHING_FISH_FARMING: 'Land- Forstwirtschaft, Fischerei und Fischzucht',
    MANUFACTURING: 'Verarbeitendes Gewerbe',
    TRAFFIC_MESSAGE_DELIVERY: 'Verkehrs-u.Nachrichtenübermittlung',
    OTHER: 'Sonstiges',
} as const;

export const EnumLikeLivingSituation = {
    RENTER: 'RENTER',
    OWNER_WITHOUT_MORTGAGE: 'OWNER_WITHOUT_MORTGAGE',
    OWNER_WITH_MORTGAGE: 'OWNER_WITH_MORTGAGE',
    BY_FAMILY: 'BY_FAMILY',
    EMPLOYER: 'EMPLOYER',
    OTHER: 'OTHER',
} as const;
export const LIVING_SITUATION: Record<keyof typeof EnumLikeLivingSituation, string> = {
    RENTER: 'Mieter',
    OWNER_WITHOUT_MORTGAGE: 'Eigentümer ohne Belastung',
    OWNER_WITH_MORTGAGE: 'Eigentümer Hypothek',
    BY_FAMILY: 'bei Familie wohnhaft (nicht Ehe-/ Lebenspartner)',
    EMPLOYER: 'Arbeitgeber',
    OTHER: 'Kein fester Wohnsitz',
} as const;

export const EnumLikeResidencyStatus = {
    LIMITED_RESIDENCE_PERMIT_PARAGRAPH_OTHERS: 'LIMITED_RESIDENCE_PERMIT_PARAGRAPH_OTHERS',
    RESIDENCE_PERMIT_CARD: 'RESIDENCE_PERMIT_CARD',
    RESIDENCE_PERMIT_SWITZERLAND: 'RESIDENCE_PERMIT_SWITZERLAND',
    LIMITED_RESIDENCE_PERMIT_PARAGRAPH_19A: 'LIMITED_RESIDENCE_PERMIT_PARAGRAPH_19A',
    PERMANENT_RESIDENCE_PERMIT: 'PERMANENT_RESIDENCE_PERMIT',
    RESIDENCE_PERMIT_OTHER: 'RESIDENCE_PERMIT_OTHER',
    UNLIMITED_RESIDENCE_PERMIT: 'UNLIMITED_RESIDENCE_PERMIT',
    RESIDENCE_CARD: 'RESIDENCE_CARD',
} as const;

export const RESIDENCY_STATUS: Record<keyof typeof EnumLikeResidencyStatus, string> = {
    LIMITED_RESIDENCE_PERMIT_PARAGRAPH_OTHERS: 'Befristete Aufenthaltserl. (nach anderen Paragrafen)',
    RESIDENCE_PERMIT_CARD: 'Daueraufenthaltskarte',
    RESIDENCE_PERMIT_SWITZERLAND: 'Aufenthaltserlaubnis-CH (Schweiz)',
    LIMITED_RESIDENCE_PERMIT_PARAGRAPH_19A: 'Befristete Aufenthaltserl. (Par. 19a oder 27-37 AufenthG)',
    PERMANENT_RESIDENCE_PERMIT: 'Niederlassungserlaubnis',
    RESIDENCE_PERMIT_OTHER: 'Anderer Titel',
    UNLIMITED_RESIDENCE_PERMIT: 'Unbefristete Aufenthaltserlaubnis',
    RESIDENCE_CARD: 'Aufenthaltskarte',
} as const;

export const CC_STATUS = {
    scheduled: 'scheduled',
	running: 'running',
	finished: 'finished',
} as const;

export const DECISION = {
	approved: 'approved',
	pending: 'pending',
	rejected: 'rejected',
	error: 'error',
	invalid: 'invalid',
	bankerror: 'bankerror',
	systemerror: 'systemerror',
	apierror: 'apierror',
} as const;
