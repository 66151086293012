export function getSeperators(locale: string) {
    const thousandSeparator = Intl.NumberFormat(locale)
        .format(11111)
        .replace(/\p{Number}/gu, '');
    const decimalSeparator = Intl.NumberFormat(locale)
        .format(1.1)
        .replace(/\p{Number}/gu, '');

    return {
        thousandSeparator,
        decimalSeparator,
    };
}

export function getNumberFromLocaleString(value: string, locale = navigator.language): number {
    const { thousandSeparator, decimalSeparator } = getSeperators(locale);
    return parseFloat(value.replace(new RegExp(`\\${thousandSeparator}`, 'g'), '').replace(new RegExp(`\\${decimalSeparator}`), '.'));
}

// TODO should we only do the decimals when there ARE actual decimals ?
export function formatLocaleNumber(value: string | number, locale = navigator.language, options?: Intl.NumberFormatOptions): string {
    const output = Intl.NumberFormat(locale, options).format(typeof value === 'number' ? value : getNumberFromLocaleString(value, locale));
    if (output === 'NaN') return typeof value === 'number' ? value.toString() : value;
    return output;
}

// TODO add way to start with a decimal separator and basically add 0 in front
// so input ',' would be changed to '0,'

// TODO fix bug that when started with decimal separator it changes it to two decimal separators
// so input ',' => ',,'
export function parseLocaleNumber(value: string | number, decimals: number, locale = navigator.language): string {
    const { decimalSeparator } = getSeperators(locale);
    if (typeof value === 'number') {
        return formatLocaleNumber(value, locale, { maximumFractionDigits: decimals });
    }
    const val = formatLocaleNumber(value, locale, {
        maximumFractionDigits: decimals,
    });

    // when the User clears the input, the value would be NaN if we do any sort of parsing ...
    if (value === '') {
        return value;
    }

    // when we dont allow values, we can just return the val
    if (decimals === 0) {
        return val;
    }

    const lastPosSeparatorValue = value.lastIndexOf(decimalSeparator);
    // when we allow decimals but the user has not entered a decimalSeparator
    if (lastPosSeparatorValue === -1) {
        return val;
    }

    // change this to the start of the function
    if (value.length === 1 && typeof value === 'string' && value === decimalSeparator) {
        return `0${decimalSeparator}`;
    }

    // when decimal separator is in the last place and we allow decimals, we can return the formatted string + the separator
    if (lastPosSeparatorValue === value.length - 1) {
        return `${val}${decimalSeparator}`;
    }

    // we allow decimals, the decimal separator is not in the last place (so user has entered decimals)
    // so we extract the formatted string vom val up to the separator
    // extract the decimals from the input (value)
    // and return them together
    const lastPosSeparatorVal = val.lastIndexOf(decimalSeparator);
    const valCleaned = val.substring(0, lastPosSeparatorVal > -1 ? lastPosSeparatorVal + 1 : undefined);
    let valueDecimals = lastPosSeparatorValue > -1 ? value.substring(lastPosSeparatorValue + 1) : '';

    // when user tries to enter more decimals than he is allowed
    if (valueDecimals.length > decimals) {
        valueDecimals = valueDecimals.slice(0, decimals);
    }
    return `${valCleaned}${valCleaned.includes(decimalSeparator) ? '' : decimalSeparator}${valueDecimals}`;
}

export type InputOutput = {
    input: string;
    output: number;
};

export function getNumberFromString(input: string): InputOutput {
    const trimmed = input.trim();
    const emptyCheck = trimmed === '' ? NaN : trimmed;
    const numValue = Number(emptyCheck);
    return {
        input,
        output: numValue,
    };
}
