import { ChangeEvent, useState } from 'react';
import { Control, FieldPathByValue, FieldValues, useController } from 'react-hook-form';
import { getLocale } from '../utils/getLocale';
import { formatLocaleNumber, getNumberFromLocaleString, parseLocaleNumber } from '../utils/number.utils';

type Props<TFieldValues extends FieldValues, TPath extends FieldPathByValue<TFieldValues, number>> = {
    control: Control<TFieldValues>;
    name: TPath;
    id: string;
    placeholder: string;
} & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

const locale = getLocale();

export function InputNumber<TFieldValues extends FieldValues, TPath extends FieldPathByValue<TFieldValues, number>>({
    name,
    control,
    id,
    placeholder,
    ...rest
}: Props<TFieldValues, TPath>) {
    const decimals = { maxDecimals: 2, minDecimals: 0 };
    const dec = decimals?.maxDecimals != null ? decimals.maxDecimals : 0;
    const { field } = useController({ name, control });
    const [inputValue, setInputValue] = useState(
        field.value != null
            ? `${formatLocaleNumber(field.value, locale, {
                  maximumFractionDigits: decimals?.maxDecimals,
                  minimumFractionDigits: decimals?.minDecimals,
              })}`
            : ''
    );

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        // the localised value
        const { value: changeValue } = e.currentTarget;

        const inputValue = parseLocaleNumber(changeValue, dec, locale);
        const numberValue = getNumberFromLocaleString(inputValue, locale);
        setInputValue(inputValue);
        field.onChange(numberValue);
    };

    const onBlur = (e: ChangeEvent<HTMLInputElement>) => {
        // the localised value
        const { value: changeValue } = e.currentTarget;
        if (changeValue.length === 0) {
            return field.onChange('');
        }
        const inputValue = parseLocaleNumber(changeValue, dec, locale);
        const numberValue = getNumberFromLocaleString(inputValue, locale);
        setInputValue(parseLocaleNumber(numberValue, dec, locale));
        field.onChange(numberValue);
    };

    return (
        <input
            type="text"
            inputMode="decimal"
            data-value={Number.isNaN(field.value) ? "" : field.value}
            id={id}
            name={name}
            value={inputValue}
            onChange={onChange}
            onBlur={onBlur}
            placeholder={placeholder}
            {...rest}
        />
    );
}
