import { zodResolver } from '@hookform/resolvers/zod';
import { SubmitHandler, useForm } from 'react-hook-form';
import { object, z } from 'zod';
import { InputNumber } from '../consors/form/InputNumber';
import { LoanSelectionRuntime } from './LoanSelectionRuntime';
import { LoanSelectionMonthlyRate } from './LoanSelectionMonthlyRate';
import { objectKeys } from '../consors/utils/utils';
import { ENUM_ALTERNATIVE_RUNTIME, ENUM_MONTHLY_RATE_RANGE, LoanSelectionMonthlyRateRange } from './LoanSelectionMonthlyRateRange';
import { getGrowthbookFeature } from '../utils/growthbook.utils';
import { LoanSelectionPossibleRuntime } from './LoanSelectionPossibleRuntime';

const schema = z.discriminatedUnion('version', [
    z.object({
        version: z.literal('default'),
        creditsum: z.number().positive().min(1500).max(100_000_000),
        runtime: z.number().multipleOf(12).positive().min(12).max(240),
        monthlyRate: z.number().positive(),
        usage: z.string(),
    }),
    z.object({
        version: z.literal('v1'),
        creditsum: z.number().positive().min(1500).max(100_000_000),
        monthlyRate: z.optional(z.number().positive()),
        runtime: z.number().multipleOf(12).positive().min(12).max(240).nullable(),
        range: z.nativeEnum(ENUM_ALTERNATIVE_RUNTIME).nullable().default(null),
        usage: z.string(),
    }),
]);

export type Schema_LoanSelection = z.infer<typeof schema>;

const pk_creditsum_value = (document.getElementById('pk_creditsum_value') as HTMLInputElement)?.value;
const pk_runtime_value = (document.getElementById('pk_runtime_value') as HTMLInputElement)?.value;
const pk_purpose_value = (document.getElementById('pk_purpose_value') as HTMLInputElement)?.value;

const defaultCreditsum = pk_creditsum_value ? parseInt(pk_creditsum_value, 10) : 20_000;
const defaultRuntime = pk_runtime_value ? parseInt(pk_runtime_value, 10) : 84;
const defaultPurpose = pk_purpose_value ? pk_purpose_value : 'freie Verfügung';

const version = getGrowthbookFeature<'default' | 'v1'>('t16-test', 'default');

export function LoanSelection() {
    const bubbleRange = window.location.pathname.includes('eigentuemerdarlehen') ? '280.000' : '120.000';
    const isUmschuldungPage = window.location.pathname.includes('umschuldung');
    const {
        control,
        handleSubmit,
        setValue,
        getValues,
        formState: { errors },
    } = useForm<Schema_LoanSelection>({
        resolver: zodResolver(schema),
        mode: 'onChange',
        defaultValues: {
            version,
            creditsum: defaultCreditsum,
            usage: defaultPurpose,
            runtime: version === 'default' ? defaultRuntime : isUmschuldungPage ? null : defaultRuntime,
            range: version === 'v1' && isUmschuldungPage ? ENUM_ALTERNATIVE_RUNTIME.RESCHEDULE : undefined,
        },
    });
    const onSubmit: SubmitHandler<Schema_LoanSelection> = () => {
        const elForm = document.createElement('form');
        const path = window.location.pathname;
        elForm.action = path.includes('pk-antrag') ? '/pk-antrag/anfrage/' : '/privatkredit/anfrage/';
        elForm.method = 'post';
        // for some weird reason, the data from react-hook-from, does not include all fields (for instance range), so we get the values via `getValues()`
        const values = getValues();
        const obj = { ...values };
        if (obj.version === 'v1') {
            if (obj.range != null) {
                obj.usage = obj.range === 'RESCHEDULE' ? 'Umschuldung' : 'freie Verfügung';
                delete obj.monthlyRate;
                // @ts-expect-error
                delete obj.runtime;
            }
            // @ts-expect-error
            delete obj.range;
            // @ts-expect-error
            delete obj.version;
        }
        objectKeys(obj).forEach((key) => {
            const elInput = document.createElement('input');
            elInput.type = 'hidden';
            elInput.name = key === 'runtime' ? 'year' : key === 'monthlyRate' ? 'monthlyrate' : key;
            elInput.value = obj[key] + '';
            elForm.appendChild(elInput);
        });
        document.body.appendChild(elForm);
        elForm.submit();
    };

    return (
        <form
            className={`personal-loan form flex ${version !== 'default' && 'personal-loanRate'}`}
            action="/privatkredit/anfrage/"
            method="post"
            id="kredit-anfragen"
            onSubmit={handleSubmit(onSubmit)}
        >
            <div className="arrow">
                <img
                    src="/img/blue-arrow_pk.svg"
                    width={60}
                    height={45}
                    alt="Ein Pfeil zeigt auf das Antragsformular für den Privatkredit"
                    title="Hier Privatkredit beantragen!"
                />
            </div>
            <div className="circle">
                <p>
                    Kredite von <span className="bold">1.500 € </span> bis <span className="bold">{bubbleRange} €</span>
                </p>
            </div>
            <div className="item-100">
                <h2>
                    Jetzt Kredit anfragen – <br className="none" />
                    unverbindlich und kostenlos
                </h2>
            </div>
            <div className="item-100 flex">
                <div className="item-40 al-top">
                    <label htmlFor="form-pk-start-input-creditSum">Wie hoch soll Ihr Kredit sein?</label>
                    <label className="euro">
                        <InputNumber<Schema_LoanSelection, 'creditsum'>
                            control={control}
                            id="form-pk-start-input-creditsum"
                            name="creditsum"
                            placeholder="Kreditbetrag eingeben"
                            aria-label="Kreditbetrag eingeben"
                        />
                    </label>
                    {errors?.creditsum && <label className="error">Der Kreditbetrag muss mindestens 1.500 € betragen</label>}
                </div>
                <LoanSelectionRuntime control={control} />
            </div>
            <div className="item-50 al-bottom">
                <a
                    href="/datenschutz/"
                    title="Der Schutz Ihrer Daten ist uns wichtig."
                    className="safety"
                    id="interaction-pk-FormDataProtectionLink"
                >
                    <img
                        width={28}
                        height={32}
                        src="/img/dsgvo-blau.svg"
                        alt="Icon eines Dokuments mit davorliegendem Schutzschild"
                        title="Der Schutz Ihrer Daten ist uns wichtig."
                    />
                    <p className="bold">Der Schutz Ihrer Daten ist uns wichtig.</p>
                    <p className="all">Klicken Sie, um mehr zu erfahren</p>
                </a>
            </div>
            <div className="item-50">
                <div className="flex result">
                    <LoanSelectionMonthlyRate control={control} />
                    <button type="submit" className="btn" title="Kredit unverbindlich anfragen" id="form-pk-start-button-loanCalcRequest">
                        Kredit anfragen
                    </button>
                </div>
            </div>
        </form>
    );
}
